<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <section class="top3-rank-wrap base-shadow">
      <div class="rank-list-wrap-head" style="margin-bottom: 10px">
        <span class="title">排行榜前三</span>
      </div>
      <RankTop3 :rankData="top3RankList" v-loading="loading" />
    </section>
    <section class="rank-list-wrap base-shadow">
      <div class="rank-list-wrap-head">
        <span class="title">所有排行榜</span>
      </div>
      <RankList :rankData="rankList" v-loading="loading" />
    </section>
    <el-backtop target=".base-page">
      <i class="el-icon-upload2"></i>
    </el-backtop>
  </section>
</template>

<script>
import RankTop3 from "./components/RankTop3";
import RankList from "./components/RankList";
import FilterSection from "./components/FilterSection";
import { GetStatisticsSignRangking } from "@/views/statisticAnalysis/api";
import { ShowApiError } from "@/request/error";
import PageHeader from "@/components/PageHeader";
export default {
  components: {
    RankTop3,
    RankList,
    FilterSection,
    PageHeader,
  },
  data() {
    return {
      loading: false,
      params: {},
      top3RankList: [],
      rankList: [],
    };
  },
  created() {
    this.getRankList();
  },
  methods: {
    getRankList() {
      this.loading = true;
      GetStatisticsSignRangking(this.params)
        .then((res) => {
          this.rankList = res.data;
          // 截取前三名
          this.top3RankList =
            this.rankList.length > 0 ? this.rankList.slice(0.3) : [];
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("拉取排行榜数据异常", err);
        });
    },
    onRefresh() {
      this.getRankList();
    },
    onFilterChange(data) {
      this.params = data;
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
$baseMargin: 10px auto;
$baseRadius: 10px;
.rank-list-wrap-head {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  .title {
    font-size: 15px;
    color: #000;
    font-weight: bolder;
  }
}
.top3-rank-wrap {
  box-sizing: border-box;
  margin: $baseMargin;
  padding: 15px;
  border-radius: $baseRadius;
  background-color: rgba($color: #fff, $alpha: .6);
}
.rank-list-wrap {
  box-sizing: border-box;
  margin: $baseMargin;
  padding: 15px;
  border-radius: $baseRadius;
  background-color: rgba($color: #fff, $alpha: .6);
  .rank-list-wrap-head {
    border-bottom: 1px solid #f1f1f1;
  }
}
</style>