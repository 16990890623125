<template>
  <section class="rank-list-container">
    <ul class="rank-list" v-if="rankData.length > 0">
      <template v-for="item in rankData">
        <li class="rank-list-item" :key="item.ownerId">
          <div class="rank-icon">
            <svg-icon iconClass="rank"></svg-icon>
          </div>
          <div class="list-item-content">
            <div class="owner-name">{{ item.ownerName }}</div>
            <div class="department-name">{{ item.departmentName }}</div>
          </div>
          <div class="list-item-count">{{ item.signedCount }} 个</div>
        </li>
      </template>
    </ul>
    <div class="no-data" v-if="rankData.length <= 0">暂无数据</div>
  </section>
</template>

<script>

export default {
  props: {
    rankData: {
      type: Array,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      
    };
  },
};
</script>

<style lang="scss" scoped>
.rank-list-container {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  .rank-list {
    width: 100%;
    .rank-list-item {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      transition: all 0.3s linear;
      padding: .5em;
      padding-right: 1.4em;
      margin-bottom: 5px;
      cursor: pointer;

      .rank-icon {
        flex: 1.3em 0 0;
        font-size: 2.5em;
        text-align: left;
        overflow: hidden;
      }
      .list-item-content {
        box-sizing: border-box;
        padding: 0 1.1em;
        flex: 1;
        overflow: hidden;
        .owner-name {
          font-size: 1.4em;
          font-weight: bolder;
          text-align: left;
          margin-top: 5px;
        }
        .department-name {
          font-size: .1em;
          font-weight: bolder;
          text-align: left;
          color: #989898;
          padding-top: .5em;
        }
      }
      .list-item-count {
        box-sizing: border-box;
        font-size: 2em;
        color: rgb(151, 151, 151);
        flex: auto 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
      &:hover {
        border-radius: 5px;
        box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
      }
    }
  }
  .no-data{
      box-sizing: border-box;
      padding: 1.1em 0;
      text-align: center;
      color: #989898;
      font-size: 1.1em;
  }
}
</style>